/**
 * Recipes Class
 */
window.Recipes = new (function () {

    const Swal = require('../js/back/plugins/sweetalert2');

    var that = this;


    this.init = function () {

        //show products dropdown
        $('.vm-drop').on('click', that.showHideFilterProductList);

        //Favorites
        $('.vm-addFavorite').on('click', that.addToFavorite);

    };

    /**
     * Show or hide
     * filter product list
     */
    this.showHideFilterProductList = function () {
        $('.vm-dropWrap').toggleClass('showOpt');
    };

    /**
     * Add to favorite
     */
    this.addToFavorite = function (e) {
        e.preventDefault();

        let theRecipeElement = $(this);
        let recipeID = $(this).data('recipe');
        let recipeSlug = $(this).data('recipeslug');
        let localUserID = $(this).data('localuser');

        if(localUserID == '') {
            window.location.replace(myAccountLoginRoute + '?recipe=' + recipeSlug);
        }

        //send data
        $.ajax({
            type: 'POST',
            url: saveFavoriteRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'recipe_id': recipeID,
            },
            success: function (response) {

                $(theRecipeElement).toggleClass('added');
                $(theRecipeElement).find('em').html(response);

            }
        });
    };

    /**
     * My recipes filter
     */
    this.myRecipesFilter = function () {

        $('.vm-recipe_filterItem').on('click', that.doRecipeFilter);

    };

    /**
     * do recipes filter
     */
    this.doRecipeFilter = function () {

        let category = $(this).data('filter');

        $('.vm-recipe_filterItem').removeClass('current');
        $(this).addClass('current');

        if(category == allCat) {
            $('.vm-myAccount_recipe').removeClass('d-none');
            return;
        }

        $.each($('.vm-myAccount_recipe'), function (input) {
            if(!$(this).hasClass(category)) {
                $(this).addClass('d-none');
            } else {
                $(this).removeClass('d-none');
            }
        });

    };

    /**
     * listener delete account
     */
    this.deleteAccount = function () {
        $('.delete-account').on('click', that.confirmDeleteAccount);
    };

    /**
     * confirm delete account
     */
    this.confirmDeleteAccount = function () {
      Swal.fire({
          title: deleteAccountTitle,
          showCancelButton: true,
          confirmButtonColor: "#ff494b",
          confirmButtonText: confirmBtn,
          cancelButtonText: cancelBtn
      }).then((result) => {
          if (result.value) {
              $('#delete-local-user-account').submit();
          }
      })
    };

})();
