//import Splitting from "splitting";
(function(){

    /** Funciones del menu en móvil */
    let menu = function(){
        $('body').toggleClass('noScroll');
    }; 

    /** Convierte a carousel los productos */
    let homesProductSldr = function(){
        let sldrProd = $('.vm-pres-sldr');

        let sldrOpt = {
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 99999,
                settings: "unslick"
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }]
        }

        sldrProd.each((index,elem) =>{
            $(elem).slick(sldrOpt);
            $(window).on('resize', function () {
                if (!$(elem).hasClass('slick-initialized')) {
                    return $(elem).slick(sldrOpt);
                }
            });
        });
    }

    /**
     * Filtro por producto
     */
    let dropDownFilter = function () {
        let dropHandler = $('.vm-drop_handler'),
            dropDown = $('.vm-dropWrap'),
            items = dropDown.find('a'),
            currentOpt = $('.vm-drop_prdct');

        dropHandler.on('click', function () {
            $(this).toggleClass('active');
            dropDown.toggleClass('showOpt');
        });

        items.each((index, elem) => {
            let el = $(elem);

            el.on('click', function (e) {
                e.preventDefault();
                currentOpt.text(el.data("product"));
                dropDown.toggleClass('showOpt');
            });

        });

        currentOpt.text(items.first().data("product"));
    }

    /*
    let.expandSearch = function () {
        let searchBox = $('.searchBox'),
            handler = $('.search-icon'),
            breackpoint = 767,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                handler.on('click', function () {
                    searchBox.toggleClass('expand');
                });
            }
        }

        mediaqueryresponse(mql)

        mql.addEventListener('change', mediaqueryresponse);
    }
     */

    let simpleTab = function(){
        let tabHandler = $('.vm-tabHdr a'),
            tabContainer = $('.vm-tabContenet'),
            breackpoint = 767,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

            function mediaqueryresponse(mql) {
                if (mql.matches) {
                    tabContainer.hide();
                    tabHandler.first().addClass("active");
                    tabContainer.first().show();
        
                    tabHandler.each((i,el) =>{
                        let elem = $(el);
                        elem.on('click', function(){
                            let activeTab = elem.attr('href');
                            $('.vm-tabHdr a').removeClass('active');
                            $(this).addClass('active');
                            tabContainer.hide();
                            $(activeTab).fadeIn();
                            return false;
                        });
                    });
                }
            }

            mediaqueryresponse(mql)

            mql.addEventListener('change', mediaqueryresponse);
    }

    /** Arranque de funciones */
    $('.menuBox .hamburger').on('click', menu);
    //Splitting();
    homesProductSldr();
    //dropDownFilter();
    simpleTab();
})();